/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@angular/cdk/overlay-prebuilt.css';

* {
  font-family: 'Inter';
}

.swagger-ui * {
  font-family: unset !important;
}

.swagger-ui {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}

/* Keep monospace font for code blocks */
.swagger-ui .highlight-code {
  font-family: 'Monaco', 'Menlo', 'Consolas', monospace !important;
}

@layer utilities {
  .flex-center {
    @apply flex items-center justify-center
  }

  .flex-col-center {
    @apply flex flex-col items-center justify-center
  }

  .grey-input {
    @apply  block w-full rounded-lg border border-secondary-300 bg-secondary-50 p-2.5 text-sm text-secondary-900 focus:border-primary-500 focus:ring-primary-500 dark:border-secondary-600 dark:bg-secondary-700 dark:text-white dark:placeholder-secondary-400 dark:focus:border-primary-500 dark:focus:ring-primary-500
  }

.primary-checkbox {
  @apply appearance-none flex-shrink-0 h-4 w-4 rounded border border-secondary-300 bg-secondary-100 text-primary-500 dark:border-secondary-600 dark:bg-secondary-700 dark:focus:bg-primary-300;

  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='white' stroke-width='1.5' d='M3.5 8l3 3 6-6'/%3e%3c/svg%3e");
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-color: transparent;
  }
}

  .white-container {
    @apply flex h-full w-full grow rounded-lg bg-white p-6 shadow-axleBlue sm:p-8 dark:bg-primary-950
  }

  .white-container-no-grow {
    @apply flex w-full rounded-lg bg-white p-6 shadow-axleBlue sm:p-8 dark:bg-primary-950
  }

  .axle-overflow-auto {
    @apply overflow-auto scrollbar scrollbar-track-transparent scrollbar-thumb-secondary-300;
  }

  .axle-overflow-x-auto {
    @apply overflow-x-auto scrollbar scrollbar-track-transparent scrollbar-thumb-secondary-300;
  }

  .axle-overflow-auto-strict {
    @extend .axle-overflow-auto;
    contain: strict;
  }

  .tooltip-text {
    @apply text-xs text-secondary-500
  }

  .time-picker-container {
    @apply block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm leading-none text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500
  }

  .tinymce-p p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  .tinymce-ol ol {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
  }

  .tinymce-ul ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
  }

  .tinymce-a a {
    color: blue;
    text-decoration: underline;
  }
}

.axle-text-secondary-50 {
  @apply text-secondary-50 dark:text-[#1F2937]
}

.axle-text-secondary-100 {
  @apply text-secondary-100 dark:text-[#24314A]
}

.axle-text-secondary-900 {
  @apply text-secondary-900 dark:text-secondary-50
}

.axle-text-secondary-500 {
  @apply text-secondary-500 dark:text-secondary-300
}

.axle-text-secondary-300 {
  @apply text-secondary-300 dark:text-secondary-500
}

.axle-text-primary-100 {
  @apply text-primary-100 dark:text-primary-700
}

// Background
.axle-bg-white {
  @apply bg-white dark:bg-[#172135]
}

.axle-bg-button-white {
  @apply bg-white hover:bg-secondary-100 dark:bg-[#172135] dark:hover:bg-[#1F2937]
}

.axle-bg-primary-100 {
  @apply bg-primary-100 hover:bg-primary-200 dark:bg-primary-700 dark:hover:bg-primary-800
}

.axle-bg-primary-500 {
  @apply bg-primary-500 hover:bg-primary-600 
}

.axle-bg-secondary-50 {
  @apply bg-secondary-50 hover:bg-secondary-100 dark:bg-[#1F2937] dark:hover:bg-[#24314A]
}

.axle-bg-secondary-100 {
  @apply bg-secondary-100 hover:bg-secondary-200 dark:bg-secondary-700 dark:hover:bg-secondary-600
}

.axle-bg-secondary-300 {
  @apply bg-secondary-300 hover:bg-secondary-400 dark:bg-secondary-500 dark:hover:bg-secondary-400
}

.axle-bg-secondary-500 {
  @apply bg-secondary-500 hover:bg-secondary-600 dark:bg-secondary-300 dark:hover:bg-secondary-200
}

.axle-bg-secondary-900 {
  @apply bg-secondary-900 hover:bg-secondary-800 dark:bg-secondary-50 dark:hover:bg-secondary-200
}

.axle-bg-green-600 {
  @apply bg-green-600 hover:bg-green-700
}

// Border
.axle-border-white {
  @apply border-white
}

.axle-border-secondary-50 {
  @apply border-secondary-50 dark:border-[#1F2937]
}

.axle-border-secondary-100 {
  @apply border-secondary-100 dark:border-[#24314A]
}

.axle-border-secondary-200 {
  @apply border-secondary-200 
}

.axle-border-secondary-900 {
  @apply border-secondary-900 dark:border-secondary-50
}

.axle-border-secondary-500 {
  @apply border-secondary-500 dark:border-secondary-300
}

.axle-border-secondary-300 {
  @apply border-secondary-300 dark:border-secondary-500
}

.axle-border-primary-100 {
  @apply border-primary-100 dark:border-primary-700
}

.axle-border-primary-500 {
  @apply border-primary-500
}


.axle-text-input {
  @apply block w-full rounded-[4px] border font-light border-secondary-300 p-2 text-sm text-secondary-900 focus:outline-none focus:ring-0 dark:border-secondary-600 dark:bg-secondary-700 dark:text-white dark:placeholder-secondary-400 dark:focus:border-primary-500 dark:focus:ring-primary-500
}

.axle-editable-label {
  @apply block w-full text-secondary-900 focus:outline-none focus:ring-0 dark:bg-secondary-700 dark:text-white 
}

.axle-editable-label-focus {
  @apply block w-full text-secondary-900 ring-2 ring-primary-500 dark:bg-secondary-700 dark:text-white 
}

.axle-search-bar {
  @apply block w-full rounded-lg border border-secondary-300 py-1.5 pr-2 ps-[34px] text-sm text-secondary-900 focus:outline-none focus:ring-0 dark:border-secondary-600 dark:bg-secondary-700 dark:text-white dark:placeholder-secondary-400 dark:focus:border-primary-500 dark:focus:ring-primary-500
}

.tox-tinymce {
  box-shadow: none!important;
  border: none !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.tox .tox-edit-area::before {
  border: none!important
}

.tox-dialog__footer-start {
  visibility: hidden !important;
}

.single-line-editor {
    .tox-tinymce {
        height: 20px !important;
    }

    .tox .tox-edit-area__iframe {
        background-color: unset !important;
    }
}

.create-description-editor {
  .tox-tinymce {
        height: 600px !important;
    }
}

.email-signature-editor {
  .tox-tinymce {
        height: 300px !important;
    }
}

.axle-email-editor {
  .tox-tinymce {
        height: calc(83vh - 300px) !important;
    }
}

.tox .tox-editor-header {
  display: flex !important;
  justify-content: center!important;
}

.intercom-lightweight-app-launcher {
  @apply hidden sm:block
}

@media screen and (max-width: 767px) {
  .cdk-global-overlay-wrapper {
    align-items: start!important;
    top: 12px!important;
  }
}

/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.table-cell {
    @apply text-sm font-light text-secondary-900 dark:text-white py-1 pr-1
}

.table-cell-wrap {
  @apply text-sm font-light text-secondary-900 dark:text-white whitespace-normal break-words line-clamp-none pr-1
}

button[aria-label="Host Participation Table"] .bi-table path,
button[data-mce-name="hostparticipationtable"] .bi-table path {
  @apply fill-primary-500
}

button[aria-label="Add attachment"] .bi-upload path,
button[data-mce-name="attachments"] .bi-upload path {
  @apply fill-primary-500
}

button[aria-label="Portal Button"] .bi-box-arrow-in-up path,
button[data-mce-name="portalbutton"] .bi-box-arrow-in-up path {
  @apply fill-primary-500
}

.required-field::after {
  content: '*';
  color: #ef4444;
}
.required-field:hover {
  cursor: text;
}

.axle-dropdown-v2 {
  @apply axle-overflow-auto relative flex max-h-[250px] transform flex-col items-start rounded-xl border-[0.5px] border-secondary-300 bg-white p-2 shadow-axleBlue transition-all duration-200 ease-out animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 ring-0 outline-0
}

.swagger-ui .info {
  display: none !important;
}

.swagger-ui .scheme-container {
  display: none !important;
}
